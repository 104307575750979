import React from 'react';
import * as styles from './RecruitPhoneSection.module.css';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';

// itemsプロパティの型を定義
interface Item {
  store: string;
  manager: string;
  phone: string;
}

interface RecruitPhoneSectionProps {
  items: Item[];
}

// コンポーネントの実装
const RecruitPhoneSection: React.FC<RecruitPhoneSectionProps> = ({ items }) => {
  return (
    <ScrollAnimation>
      <section className={styles.section} id="recruit-phone">
        <div className={styles.container}>
          {items.map((item, index) => (
            <div key={index} className={styles.item}>
              <div>{item.store}</div>
              <div>担当: {item.manager}</div>
              <div>
                <a href={`tel:${item.phone}`}>{item.phone}</a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default RecruitPhoneSection;
