import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import H1CenterSection from '../components/H1CenterSection/H1CenterSection';
import FeatureSectionLeft from '../components/FeatureSectionLeft/FeatureSectionLeft';
import FeatureSectionRight from '../components/FeatureSectionRight/FeatureSectionRight';
import RecruitPhoneSection from '../components/RecruitPhoneSection/RecruitPhoneSection';
import FeatureSectionCenterNoimage from '../components/FeatureSectionCenterNoimage/FeatureSectionCenterNoimage';

const WazaiRecruitPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <H1CenterSection title="求人情報" />
        <FeatureSectionLeft
          title="団らん処 和菜屋"
          description={`
          団らん処 和菜屋は、素材にこだわる手作り惣菜店です。
          私たちは、
          ・健康的な食事のご提供
          ・心豊かな高齢化社会の実現
          ・地元農業の課題解決への取組み
          を目的に運営をしています。

          “皆様の健康を考えた食事を提供するとともに会話や笑顔を届けたい”

          そんな想いで日々頑張っています。
        `}
          sectionId="section5"
          imageSrc="/wazai/hero3.png"
        />
        <FeatureSectionRight
          title="デイサービス 和才屋"
          description={`
        デイサービス和才屋は、地域密着型のデイサービスです。
        私たちは、
        ・家庭のように安心して過ごしていただける空間の提供
        ・デイサービスでの活動を通した利用者様の地域交流（社会的役割づくり）
        ・食事や日々の活動を通した健康増進
        を目的に運営をしています。
        
        "高齢者が地域でより心豊かに安心して過ごせるよう応援したい"
        そんな想いで日々頑張っています。
      `}
          sectionId="section2"
          imageSrc="/wazai/wazai111.webp"
        />
        <FeatureSectionCenterNoimage
          description={`
        「まずは見学してみたい」「まずは話を聞いてみたい」というだけでも構いません。

        ご興味のある方は、ぜひお電話ください。
      `}
          sectionId="section3"
        />
        <RecruitPhoneSection
          items={[
            {
              store: 'デイサービス和菜屋 金田店',
              manager: 'ミムラ',
              phone: '093-562-7420',
            },
            {
              store: 'デイサービス和菜屋 木町店',
              manager: 'シブヤ',
              phone: '093-383-0390',
            },
          ]}
        />
      </Layout>
    </div>
  );
};

export default WazaiRecruitPage;

export const Head = () => (
  <Seo title={'求人情報'} description="求人情報のページです。" />
);
