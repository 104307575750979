import React, { useState, useEffect, useRef } from 'react';
import * as styles from './HeroCarousel.module.css';

type Slide = {
  id: string;
  image: string;
  title: string;
  description: string;
};

type HeroCarouselProps = {
  slides: Slide[];
  interval?: number;
};

const HeroCarousel: React.FC<HeroCarouselProps> = ({
  slides,
  interval = 5000,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, interval);

    return () => clearInterval(slideInterval);
  }, [interval, slides.length]);

  useEffect(() => {
    if (slideRef.current) {
      slideRef.current.style.transform = `translateX(-${currentSlide * 100}%)`;
    }
  }, [currentSlide]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 < 0 ? slides.length - 1 : prev - 1));
  };

  return (
    <div className={styles['hero']}>
      <div className={styles['hero__slides']} ref={slideRef}>
        {slides.map((slide, index) => (
          <div key={slide.id} className={styles['hero__slide']}>
            <img src={slide.image} alt={slide.title} />
            <div className={styles['hero__content']}>
              <h2>{slide.title}</h2>
              <p>{slide.description}</p>
            </div>
          </div>
        ))}
      </div>
      <button
        className={styles['hero__button__prev']}
        onClick={prevSlide}
        aria-label="prevSlide"
      >
        <div></div>
      </button>
      <button
        className={styles['hero__button__next']}
        onClick={nextSlide}
        aria-label="nextSlide"
      >
        <div></div>
      </button>
    </div>
  );
};

export default HeroCarousel;
