import * as React from 'react';
import Header from '../components/Header101/Header';
import HeroCarousel from '../components/HeroCarousel/HeroCarousel';
import Footer from '../components/Footer6/Footer';

const Layout = ({ children }) => {
  return (
    <div>
      <Header
        title="団らん処 和菜屋"
        logoImage="/wazai/logo.png"
        logoImage2="/wazai/logo2.png"
        navItems={[
          {
            id: 1,
            name: '会社概要',
            link: '/about',
            dropdown: true,
            subItems: [
              { name: '団らん処和菜屋', link: '/' },
              { name: 'デイサービス和才屋', link: '/dayservice' },
            ],
          },
          {
            id: 2,
            name: '創業者の想い',
            link: '/founder',
            dropdown: false,
          },
          {
            id: 3,
            name: 'アクセス',
            link: '/access',
            dropdown: false,
          },
          {
            id: 4,
            name: '求人情報',
            link: '/recruit',
            dropdown: false,
          },
        ]}
      />
      <HeroCarousel
        slides={[
          {
            id: 1,
            image: '/wazai/hero1.png',
            alt: 'Image 1',
            title: '団らん処 和菜屋',
          },
          {
            id: 2,
            image: '/wazai/hero2.png',
            alt: 'Image 2',
            title: '旬の食材を使った料理お惣菜を提供いたします。',
          },
          { id: 3, image: '/wazai/hero5.png', alt: 'Image 4' },
        ]}
      />
      {children}
      <Footer
        items={[
          {
            categoriesname: '事業内容',
            categories: [
              { name: '団らん処和菜屋', link: '/' },
              { name: 'デイサービス和才屋', link: '/dayservice' },
              {
                name: 'ネットショップ(準備中)',
                link: 'https://wazaiya.base.shop/',
              },
              { name: 'アクセス', link: '/access' },
            ],
          },
          {
            categoriesname: '会社概要',
            categories: [
              { name: '会社概要', link: '/about' },
              { name: '創業者の想い', link: '/founder' },
              { name: '活動実績', link: '/history' },
              { name: 'プライバシーポリシー', link: '/privacy' },
            ],
          },
          {
            categoriesname: '採用',
            categories: [{ name: '求人情報', link: '/recruit' }],
          },
        ]}
        logo="/wazai/logo.png"
        logoAltText="和菜屋"
        copyrightText="団らん処 和菜屋"
        logoLinkText="/"
        facebookUrl="https://www.facebook.com/wazaiya.kokura/"
        xUrl="https://twitter.com/wazaiya_sozai"
        instagramUrl="https://www.instagram.com/wazaiya.sozai/"
      />
    </div>
  );
};

export default Layout;
